import React, { createContext, useReducer } from "react";

import Reducer from "./reducer";

const initial = { toast: null, loading: false };

const InfoContext = createContext(initial);

export function InfoContainer({ children }) {
    const [infoState, infoDispatch] = useReducer(Reducer, initial);

    return (
        <InfoContext.Provider value={[infoState, infoDispatch]}>
            {children}
        </InfoContext.Provider>
    );
}

export default InfoContext;
