import React, { useContext, useEffect } from "react";

import GlobalContext from "globals/info";

import View from "./view";

export default function Toasts() {
    const [{ toast }, infoDispatch] = useContext(GlobalContext);

    useEffect(() => {
        if (!toast) return;
        if (!toast.text) return;

        const toastId = toast.inline ? "toasts-inline" : "toasts";

        const toasts = document.getElementById(toastId);

        const alert = document.createElement("div");
        const alertType = toast.type ?? "alert-secondary";
        alert.className = `alert ${alertType} alert-dismissible mw-100 fade show`;
        alert.setAttribute("role", "alert");
        alert.innerText = toast.text;

        const container = document.createElement("div");
        container.className = "col-12";
        container.appendChild(alert);

        toasts.appendChild(container);
        infoDispatch({ type: "toast" });
        setTimeout(() => container.remove(), 2500);
    }, [toast, infoDispatch]);

    return <View />;
}
