import React, {
    createContext,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";

import Reducer from "./reducer";

import Loading from "atoms/Loading";

import payloads from "config/payloads.json";

import InfoContext from "globals/info";

import getUserAPI from "utils/api/user/getUserAPI";

import Session from "utils/session";

const initial = {
    user: null,
    metadata: null,
};

const SessionContext = createContext(initial);

export function SessionContainer({ children }) {
    const [error, setError] = useState(null);
    const [hasLoaded, setLoaded] = useState(false);

    const [, iDispatch] = useContext(InfoContext);
    const [state, dispatch] = useReducer(Reducer, initial);

    useEffect(() => {
        return Session.authState((metadata) => {
            if (!metadata) setLoaded(true);

            const payload = metadata && {
                id: metadata.uid,
                image: metadata.photoURL,
            };

            dispatch({ type: "set-metadata", payload });
        });
    }, []);

    useEffect(() => {
        if (state.user) return;
        if (!state.metadata) return;

        getUserAPI(state.metadata.id)
            .then((payload) => dispatch({ type: "set-user", payload }))
            .catch(setError);
    }, [state]);

    useEffect(() => {
        if (!state.user) return;
        if (state.metadata) return;

        dispatch({ type: "set-user", payload: null });
    }, [state]);

    useEffect(() => {
        if (!error) return;

        iDispatch(payloads.ACCOUNT_NOT_FOUND);
        Session.signOut().catch((error) => {
            iDispatch(payloads.INCONSISTENT_AUTH_STATE);
        });
    }, [error, iDispatch]);

    useEffect(() => {
        if (!state.metadata) return;
        return setLoaded(true);
    }, [state]);

    if (!hasLoaded) return <Loading forced />;

    return (
        <SessionContext.Provider value={[state, dispatch]}>
            {children}
        </SessionContext.Provider>
    );
}

export default SessionContext;
