import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import "utils/session";

import Toasts from "atoms/Toasts";
import Loading from "atoms/Loading";

import { InfoContainer } from "globals/info";
import { SessionContainer } from "globals/session";

import Routes from "./routes";

ReactDOM.render(
    <React.StrictMode>
        <InfoContainer>
            <SessionContainer>
                <Toasts />
                <Loading />
                <Routes />
            </SessionContainer>
        </InfoContainer>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
