import firebase from "firebase/app";
import "firebase/auth";
import getUserAPI from "utils/api/user/getUserAPI";
import onBoardUser from "utils/api/user/onboardUser";

const { Auth, OAuthProvider, GoogleAuthProvider } = firebase.auth;

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId:
            process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    });
}

export default class Session {
    static async getIdToken() {
        const user = firebase.auth().currentUser;
        if(!user) return "";
        return await user.getIdToken(true);
    }

    static async signIn(isOAuth, providerOrToken) {
        if (firebase.auth().currentUser) return;

        await firebase.auth().setPersistence(Auth.Persistence.LOCAL);

        if (isOAuth) {
            await firebase.auth().signInWithPopup(providerOrToken);
        } else {
            await firebase.auth().signInWithCustomToken(providerOrToken);
        }
        const user = firebase.auth().currentUser;
        let firestoreUser =await getUserAPI(user.uid);
        if(!firestoreUser.id){
            console.log("user not found in firestore, onboarding user");
            onBoardUser(user.uid).then(()=>{
            window.location.reload(false);
           });
           
        }
        return await Session.getIdToken();
    }

    static getGoogleProvider() {
        return new GoogleAuthProvider();
    }

    static getAppleProvider() {
        return new OAuthProvider("apple.com");
    }

    static async signOut() {
        return await firebase.auth().signOut();
    }

    static authState(callback) {
        return firebase.auth().onAuthStateChanged(callback);
    }
}
