import Session from "utils/session";
import getInstance, { getReduceFilter, getRenameFilter } from "utils/api";

export default async function getUserAPI(userId) {
    const token = await Session.getIdToken();
    const instance = getInstance("users", userId);

    const reduce = getReduceFilter("id", "uid", "name", "handle", "image");
    const rename = getRenameFilter({
        displayPicture: "image",
        createdOn: "uid",
    });

    const config = {
        params: { reduce, rename },
        headers: { "x-custom-authorization": token },
    };

    return (await instance.get("", config)).data;
}
