import Session from "utils/session";
import getInstance from "utils/api";

export default async function onBoardUser(uid) {
    const token = await Session.getIdToken();
    const instance = getInstance("users", "onboard-user", uid);
    const body = {};
    const config = {
        headers: { "x-custom-authorization": token },
    };
    const result = (await instance.post("",body, config)).data;
    return result
}
