const Reducer = (state, { type, payload }) => {
    const actions = {
        'loading': (state, payload) => ({ ...state, loading: payload }),
        'toast': (state, payload) => ({ ...state, toast: payload ?? null }),
        'notify': (state, payload) => ({
            ...state,
            toast: {
                type: 'alert-info',
                ...payload
            }
        }),
        'notify-danger': (state, payload) => ({
            ...state,
            toast: {
                type: 'alert-danger',
                ...payload
            }
        }),
        'notify-success': (state, payload) => ({
            ...state,
            toast: {
                type: 'alert-success',
                ...payload
            }
        }),
        'notify-warning': (state, payload) => ({
            ...state,
            toast: {
                type: 'alert-warning',
                ...payload
            }
        })
    };

    const action = actions[type];
    return action ? action(state, payload) : state;
};

export default Reducer;
