import React, { useContext } from "react";

import GlobalContext from "globals/info";

import View from "./view";

export default function Loading({ forced }) {
    const [{ loading }] = useContext(GlobalContext);

    return <View loading={loading} forced={forced} />;
}
