import React from "react";

export default function View({ loading, forced }) {
    if (!(loading || forced)) return "";

    return (
        <div className="d-flex fixed-top bg-ombre-black vh-100 vw-100 justify-content-center align-items-center z-1050">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="spinner-border text-primary spinner-custom"></div>
            </div>
        </div>
    );
}
