import React, { useContext } from "react";
import { Redirect, Route, useParams } from "react-router-dom";

import SessionContext from "globals/session";

import { useQuery } from "utils";

export default function ProtectedRoute({ ...rest }) {
    const { id } = useParams();
    const { token } = useQuery(["token"]);
    const [{ metadata }] = useContext(SessionContext);

    // if (!metadata) {
    //     const queryParams = [];
    //     const baseURL = "/sign-in";

    //     if (id) queryParams.push(`callback=${id}`);
    //     if (token) queryParams.push(`token=${token}`);

    //     const queryString = queryParams.join("&");
    //     const redirectURL = queryString
    //         ? `${baseURL}?${queryString}`
    //         : baseURL;

    //     return <Redirect to={redirectURL} />;
    // }

    return <Route {...rest} />;
}
