import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Loading from "atoms/Loading";
import ProtectedRoute from "atoms/ProtectedRoute";
const Home = React.lazy(() => import("pages/Home"));
const SignIn = React.lazy(() => import("pages/SignIn"));
const Stream = React.lazy(() => import("pages/Stream"));
const StreamRecording = React.lazy(() => import("pages/StreamRecordings"));

export default function Routes() {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading forced={true} />}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/sign-in" component={SignIn} />
                    <ProtectedRoute exact path="/event/:id" component={StreamRecording} fallback="/sign-in" />
                    <ProtectedRoute
                        exact
                        path="/:id"
                        component={Stream}
                        fallback="/sign-in"
                    />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}
