import axios from "axios";

export default function getInstance(...path) {
    const base = process.env.REACT_APP_BACKEND_URL;
    const baseURL = [base, ...path].join("/");
    return axios.create({ baseURL });
}

export function getReduceFilter(...fields) {
    return fields.join(",");
}

export function getPopulateFilter(...fields) {
    return fields.join(",");
}

export function getRenameFilter(json) {
    return Object.entries(json)
        .map((fields) => fields.join(":"))
        .join(",");
}
