const Reducer = (state, { type, payload }) => {
    const actions = {
        "set-verification-status": (state, isVerifying) => ({
            ...state,
            isVerifying,
        }),
        "set-metadata": (state, metadata) => ({
            ...state,
            metadata,
        }),
        "set-user": (state, user) => ({
            ...state,
            user,
        }),
    };

    const action = actions[type];
    return action ? action(state, payload) : state;
};

export default Reducer;
