import { useLocation } from "react-router";

export function useQuery(array = []) {
    const query = {};
    const search = new URLSearchParams(useLocation().search);
    array.forEach(key => (query[key] = search.get(key)));
    return query;
}

export function getRelativeDate(start, end) {
    const now = new Date().getTime();

    if (now >= end) return 'Expired';
    if (now >= start) return 'Ongoing';

    const date = new Date(start - now);

    const instances = [
        { fn: () => date.getUTCFullYear(), tag: 'year', offset: 1970 },
        { fn: () => date.getUTCMonth(), tag: 'month', offset: 0 },
        { fn: () => date.getUTCDate(), tag: 'day', offset: 1 },
        { fn: () => date.getUTCHours(), tag: 'hour', offset: 0 },
        { fn: () => date.getUTCMinutes(), tag: 'minute', offset: 0 },
        { fn: () => date.getUTCSeconds(), tag: 'second', offset: 0 },
    ];

    const handlePlural = (count, string) => {
        if (count === 1) return string;
        return `${string}s`;
    }

    for (const { fn, tag, offset } of instances) {
        const count = fn() - offset;
        if (count) return `${count} ${handlePlural(count, tag)} remains`
    }

    return 'Ongoing';
}

export function userHashToJSON(userHash) {
    const json = {};
    const pairs = userHash.split(';')
    for (const pair of pairs) {
        const [key, value] = pair.split(':');
        json[key] = value;
    }
    return json;
}

export async function sleep(milliseconds = 1000) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}
